
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch
} from 'vue'
import Card from 'primevue/card'
import Button from 'primevue/button'
import { useRoute, useRouter } from 'vue-router'
import apiUseCase from '@/usecase/apiUseCase'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import UpdateExpense from '@/views/pages/approve-deposit/components/UpdateExpenses.vue'
import ExpenseHistory from '@/views/pages/approve-deposit/components/ExpenseHistory.vue'
import ApproveModal from '@/views/pages/approve-deposit/components/ApproveModal.vue'
import IResponse from '@/utils/interfaces/iResponse'
import moment from 'moment'
import { isNull } from 'lodash'
import Popper from 'vue3-popper'
import { useStore } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'
import { IDeposit, IDepositRit } from '@/utils/interfaces/iDeposit'
import { IRit, IRitExpenseRevisionLatest } from '@/utils/interfaces/iRit'
import IncomeTicketsDetail from './components/IncomeTicketsDetail.vue'

const GOOGLE_MAPS_API_KEY = 'AIzaSyBHsQPcuRrfglDvRqFtg57GsxCh1RfVTw8'

const totalExpense = 0

const profits: {
  id: number
  destination: string
  passenger: string
  price: number
}[] = []

const expenses: {
  id: number
  name: string
  price: number
}[] = []

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places']
})

type TLatestExpensiveRevision = {
  ExpenseName: string,
  Nominal: number
}

const endpoint = 'transaction/v1/TransactionPp'
const endpointLatestExpensesRevision = 'transaction/v1/RitExpenseRevision/Latest-Revision'

export default defineComponent({
  name: 'ApproveDepositDetail',
  components: {
    Card,
    HjpTabView,
    UpdateExpense,
    IncomeTicketsDetail,
    ExpenseHistory,
    ApproveModal,
    Popper,
    Button
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const dataId = ref<string>(route.params?.id as string ?? '')
    const dataSource = ref<IDeposit>()
    const dataRitDetail = ref<IDepositRit>()
    const ritList = ref([]) as any
    const latestExpenseRevision = ref<TLatestExpensiveRevision[]>([])
    const latestExpenseRevisionTemp = ref<IRitExpenseRevisionLatest>()
    const latestRevisionDate = ref(null) as any
    const isLoading = ref(true)
    const grandTotalIncome = ref(0)
    const grandtotalExpense = ref(0)
    const isShowDialogUpdateExpense = ref(false)
    const isShowDialogIncomeTicketsDetail = ref(false)
    const isShowDialogExpenseHistory = ref(false)
    const activeRitId = ref<string>('')
    const coordinateRitList = ref([]) as any
    const coordinateFirstLastTerminal = ref([]) as any
    const isShowApproveDialog = ref(false)
    const baseRoute = 'approve-deposits'
    const showControllerListId = ref(null)
    const routeName = (route.name as string).replace('-detail', '')
    const totalPenalty = ref(0)
    const isAllApproved = ref(true)
    const tooltipText = ref('Terdapat Perubahan Pengeluaran Di Rit Yang Belum Dikonfirmasi Oleh Kondektur')
    const currTabIndex = ref(0)
    const incomeDetailFilterOptions = ref([]) as any
    const dataIncomeDetail = {
      regNumber: '-',
      originCity: '-',
      destinationCity: '-',
      routeName: '-',
      ritDate: '-',
      totalPassenger: 0,
      totalPassengerOnline: 0,
      totalPassengerCheckIn: 0,
      totalExpiredTicket: 0,
      totalIncome: 0
    }

    let map = null as any

    const getIsApproveRit = (x: any) => {
      let val = false
      if (!x.IsAnyExpenseRevisions) {
        val = true
      } else if (x.ExpenseRevisionsIsApproved && x.IsAnyExpenseRevisions) {
        val = true
      }
      return !val
    }

    const getRitCoordinate = async () => {
      if (dataRitDetail?.value) {
        console.log(dataRitDetail?.value)
        // const { error, result } = await apiUseCase.get(`/management/v1/MsRoute/${dataRitDetail.value.RouteId}`)
        // if (!error) {
        //   const resultMap: IRouteResponse = result
        //   resultMap.Terminal.forEach((item, i) => {
        //     if (i !== 0 && i !== resultMap.Terminal.length - 1) {
        //       const ritTemp = {
        //         location: `${item.Latitude},${item.Longitude}`,
        //         stopover: true,
        //       }
        //       coordinateRitList.value.push(ritTemp)
        //     } else {
        //       coordinateFirstLastTerminal.value.push(`${item.Latitude},${item.Longitude}`)
        //     }
        //   })
        // }
        const data = dataRitDetail.value.TransactionStationLogs
        if (data.length === 1) {
          for (let i = 0; i < data.length + 1; i++) {
            coordinateFirstLastTerminal.value.push(`${data[0].Latitude},${data[0].Longitude}`)
          }
        } else {
          data.forEach((item, i) => {
            if (i !== 0 && i !== data.length - 1) {
              const ritTemp = {
                location: `${item.Latitude},${item.Longitude}`,
                stopover: true,
              }
              coordinateRitList.value.push(ritTemp)
            } else {
              coordinateFirstLastTerminal.value.push(`${item.Latitude},${item.Longitude}`)
            }
          })
        }
        console.log('coordinateFirstLastTerminal', coordinateFirstLastTerminal.value)
      }
    }

    const countIncomeTotal = () => {
      grandTotalIncome.value = 0
      if (dataRitDetail.value) {
        // eslint-disable-next-line array-callback-return
        dataRitDetail.value.TotalTicketGroupByOriginDestination.map((x: any) => {
          grandTotalIncome.value += x.TotalTicketAmount
        })
        // eslint-disable-next-line array-callback-return
        dataRitDetail.value.RitOtherRevenues.map((x: any) => {
          grandTotalIncome.value += x.Value
        })
      }
    }

    const countOutcomeTotal = () => {
      grandtotalExpense.value = 0
      if (dataRitDetail.value) {
        // eslint-disable-next-line array-callback-return, no-unused-expressions
        dataRitDetail.value?.RitExpenses.map((x: any) => {
          grandtotalExpense.value += x.Nominal
        })
      }
    }

    const getLatestExpenseRevision = async () => {
      const {
        error,
        result
      } = await apiUseCase.get(`${endpointLatestExpensesRevision}/${activeRitId.value}`)
      if (!error) {
        const resultMap: IRitExpenseRevisionLatest = result
        latestExpenseRevisionTemp.value = resultMap
        if (latestExpenseRevisionTemp.value.Items?.length > 0) {
          latestRevisionDate.value = latestExpenseRevisionTemp.value.UpdatedAt
          latestExpenseRevision.value = latestExpenseRevisionTemp.value.Items.map((x) => ({
            ExpenseName: x.ExpenseName,
            Nominal: x.RevisiAmount
          }))
          grandtotalExpense.value = latestExpenseRevisionTemp.value.TotalRevisiAmount
        } else {
          latestExpenseRevision.value = dataRitDetail.value?.RitExpenses.map((x) => ({
            ExpenseName: x.ExpenseName,
            Nominal: x.Nominal
          })) as TLatestExpensiveRevision[]
          countOutcomeTotal()
        }
      }
    }

    const getTotalPenalty = () => {
      totalPenalty.value = 0
      const refundAmmount = dataSource.value?.TrayekClassRefundAmount ?? 0
      if (!isNull(dataRitDetail.value?.Verpals)) {
        totalPenalty.value = dataRitDetail.value?.Verpals.VerpalDetails.length * refundAmmount
      }
    }

    const initMap = () => {
      loader.load().then(() => {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        // eslint-disable-next-line no-undef
        const directionsRenderer = new google.maps.DirectionsRenderer()
        // eslint-disable-next-line no-undef
        map = new google.maps.Map(document.getElementById('mapDiv') as HTMLElement, {
          center: {
            lat: 0,
            lng: 0
          },
          zoom: 15,
        })
        directionsRenderer.setMap(map)
        try {
          directionsService.route({
            origin: coordinateFirstLastTerminal.value[0],
            destination: coordinateFirstLastTerminal.value[1],
            waypoints: coordinateRitList.value,
            optimizeWaypoints: true,
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING
          }).then((response) => {
            directionsRenderer.setDirections(response)
          })
        } catch (error) {
          console.log(error)
        }
      })
    }

    const setBreadcrumbs = () => {
      store.dispatch('ui/setBreadcrumbs', [
        {
          title: 'Approve Setoran',
          routeName
        },
        {
          title: dataSource.value?.TrxId,
          routeName: `${route.name as string}`
        }
      ])
    }

    const mappingTerminalFilterOptions = () => {
      incomeDetailFilterOptions.value = dataRitDetail.value?.TotalTicketGroupByOriginDestination.map((data: any) => ({
        label: `${data.OriginTerminalCode} - ${data.DestinationTerminalCode}`,
        value: data
      }))
    }

    const getRitDetail = async (val: string) => {
      coordinateRitList.value = []
      activeRitId.value = val
      const {
        result,
        error
      } = await apiUseCase.get(`${endpoint}/Deposit-Rit/${val}`)
      if (!error) {
        dataRitDetail.value = result
        dataIncomeDetail.totalIncome = result.TotalTicketAmount
        dataIncomeDetail.totalPassenger = result.TotalTicketSold
        dataIncomeDetail.totalPassengerOnline = result.TotalTicketSoldOnline
        dataIncomeDetail.totalPassengerCheckIn = result.TotalTicketCheckin
        dataIncomeDetail.totalExpiredTicket = result.TotalTicketExpired
        dataIncomeDetail.originCity = result.FirstTerminal
        dataIncomeDetail.destinationCity = result.LastTerminal
        dataIncomeDetail.routeName = result.RouteName
        dataIncomeDetail.ritDate = moment(result.StartDate.toString()).format('DD MMMM YYYY')
        mappingTerminalFilterOptions()
        setBreadcrumbs()
        await getRitCoordinate()
        countIncomeTotal()
        getLatestExpenseRevision()
        getTotalPenalty()
        isLoading.value = false
        initMap()
      }
    }

    const getIsAllApproveRits = () => {
      if (dataSource.value) {
        for (let i = 0; i < dataSource.value?.Rits.length; i++) {
          if (dataSource.value?.Rits[i].IsAnyExpenseRevisions && !dataSource.value?.Rits[i].ExpenseRevisionsIsApproved) {
            isAllApproved.value = false
            break
          }
        }
      }
    }

    const getRitList = () => {
      ritList.value = []
      if (dataSource.value) {
        dataSource.value.Rits.forEach((item: IRit, i: number) => {
          const temp = {
            title: `Rit ke ${i + 1}`,
            error: getIsApproveRit(item),
            value: item.Id
          }
          ritList.value.push(temp)
        })
      }
    }

    const getDataDetail = async () => {
      store.dispatch('showLoading')
      try {
        const { result, error } = await apiUseCase.get(`${endpoint}/Deposit/${dataId.value}`)
        if (!error) {
          const resultMap: IDeposit = result
          dataSource.value = resultMap
          dataIncomeDetail.regNumber = dataSource.value.VehicleRegNumber
          getRitList()
          getIsAllApproveRits()
          if (dataSource.value?.Rits.length > 0 && !activeRitId.value) {
            getRitDetail(dataSource.value.Rits[0].Id)
          }
        }
        store.dispatch('hideLoading')
      } catch (error) {
        store.dispatch('hideLoading')
      }
    }

    const tabsChange = (val: any, index: any) => {
      activeRitId.value = val
      currTabIndex.value = index
      getDataDetail()
      getRitDetail(val)
      // isLoading.value = true
    }

    const showDialogUpdateExpense = () => {
      store.dispatch('showLoading')
      isShowDialogUpdateExpense.value = true
    }

    const hideDialogUpdateExpense = () => {
      isShowDialogUpdateExpense.value = false
    }

    const showDialogExpenseHistory = () => {
      store.dispatch('showLoading')
      isShowDialogExpenseHistory.value = true
    }

    const hideDialogExpenseHistory = () => {
      isShowDialogExpenseHistory.value = false
    }

    const showDialogIncomeTicketsDetail = () => {
      // store.dispatch('showLoading')
      isShowDialogIncomeTicketsDetail.value = true
    }

    const hideDialogIncomeTicketsDetail = () => {
      isShowDialogIncomeTicketsDetail.value = false
    }

    const toList = () => {
      router.push({
        // path: baseRoute.value
        name: `${baseRoute}`
      })
    }

    const hideApproveDialog = () => {
      isShowApproveDialog.value = false
    }

    const onSubmitConfirmation = (val: any = '') => {
      store.dispatch('showLoading')
      // const deletedId = val === '' ? dataRow.value.Id : val
      apiUseCase.postOrPut(`${endpoint}/Approve-Deposit`, dataId.value, null).then((res: IResponse) => {
        if (res.error) {
          $toast.add({
            severity: 'error',
            // summary: '',
            detail: 'Gagal melakukan Approve Setoran',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'success',
            // summary: 'Berhasil melakukan Approve Setoran',
            detail: 'Berhasil melakukan Approve Setoran',
            group: 'bc',
            closable: false,
            life: 3000
          })
          isShowApproveDialog.value = false
          toList()
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideApproveDialog()
      store.dispatch('hideLoading')
    }

    const approveAct = () => {
      store.dispatch('showLoading')
      isShowApproveDialog.value = true
    }

    const limitVerbatim = (val: any, length: any = 90) => {
      const text = val.length > length ? `${val.slice(0, length)}...` : val
      return text
    }

    const openVerbalDetail = (idVerbal: any) => {
      router.push({
        name: 'verbal-report-detail',
        params: {
          id: idVerbal
        }
      })
    }

    const openControllersList = (idList: any) => {
      showControllerListId.value = showControllerListId.value === idList ? null : idList
    }

    const goDetail = (item: any) => {
      const token = localStorage.getItem('ut')
      const routeData = router.resolve({ name: 'reports-detail', params: { typeReport: `${item.Report.Query}?TransactionPpId=${item.Id}&Token=${token}` } })
      window.open(routeData.href, '_blank')
    }

    const reloadUpdateExpense = () => {
      console.log('reloadUpdateExpense')
      getRitDetail(activeRitId.value)
    }

    onMounted(() => {
      getDataDetail()
    })

    watch(() => store.getters['approvePp/getReloadApprovePp'], (val) => {
      if (val) {
        getDataDetail()
        getRitDetail(activeRitId.value)
      }
    })

    return {
      profits,
      expenses,
      totalExpense,
      dataSource,
      isLoading,
      currencyNumberFormat,
      ritList,
      tabsChange,
      dataRitDetail,
      grandTotalIncome,
      grandtotalExpense,
      isShowDialogUpdateExpense,
      isShowDialogExpenseHistory,
      showDialogUpdateExpense,
      hideDialogUpdateExpense,
      activeRitId,
      showDialogExpenseHistory,
      hideDialogExpenseHistory,
      dataId,
      isShowApproveDialog,
      onSubmitConfirmation,
      approveAct,
      hideApproveDialog,
      moment,
      limitVerbatim,
      openVerbalDetail,
      showControllerListId,
      openControllersList,
      latestExpenseRevision,
      latestRevisionDate,
      isNull,
      totalPenalty,
      isAllApproved,
      goDetail,
      tooltipText,
      currTabIndex,
      getLatestExpenseRevision,
      reloadUpdateExpense,
      isShowDialogIncomeTicketsDetail,
      showDialogIncomeTicketsDetail,
      hideDialogIncomeTicketsDetail,
      dataIncomeDetail,
      incomeDetailFilterOptions
    }
  }
})
