
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import OverlayPanel from 'primevue/overlaypanel'
import Checkbox from 'primevue/checkbox'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import {
  getCurrentInstance,
  onMounted,
  ref,
  toRefs
} from 'vue'
import moment from 'moment'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'
import apiUseCase from '@/usecase/apiUseCase'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import { useStore } from 'vuex'

const endpointRit = 'transaction/v1/Rit'

export default {
  components: {
    Dialog,
    Button,
    InputIconRight,
    DataTable,
    Column,
    Paginator,
    OverlayPanel,
    Checkbox
    // InputText
  },
  props: {
    trxId: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    ritId: {
      type: String,
      default: ''
    },
    dataDetail: {
      default: {
        regNumber: '-',
        originCity: '-',
        destinationCity: '-',
        routeName: '-',
        ritDate: '-',
        totalPassenger: 0,
        totalPassengerOnline: 0,
        totalPassengerCheckIn: 0,
        totalExpiredTicket: 0,
        totalIncome: 0
      }
    },
    filterOptions: {
      default: [{ label: null, value: null }]
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, { emit }: any) {
    const { show, trxId, ritId, dataDetail, filterOptions } = toRefs(props)
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast,
      $icon
    } = app!.appContext.config.globalProperties
    const icon = $icon
    const showModal = show.value
    const refOverlayAction = ref()
    const inputSearch = ref('')
    const placeholder = ref('Cari Disini...')
    const selectedFilter = ref([])
    const dataSource = ref([]) as any
    const filters = ref({
      search: '',
      filterField: ['code'],
      pageNumber: 1,
      pageSize: 10,
      custom: [] as any
    })
    const totalTrayekRecords = ref(0)
    const date = ref('2024-01-02 10:10:10')
    const filterListDummy = ref([
      {
        id: 1,
        label: 'TLG - TLG',
        value: 1
      },
      {
        id: 2,
        label: 'TLG - SBY',
        value: 2
      },
      {
        id: 3,
        label: 'SBY - TLG',
        value: 3
      }
    ])
    const dummyData = ref([
      {
        rit: 'TLG - TLG',
        passengertype: 'UMUM',
        waNumber: '089089786789',
        price: 40000,
        ticketType: 'NORMAL',
        ticketCode: '5o2N46.1'
      },
      {
        rit: 'TLG - TLG',
        passengertype: 'PELAJAR',
        waNumber: '089089786788',
        price: 20000,
        ticketType: 'NORMAL',
        ticketCode: '5o2N46.2'
      },
      {
        rit: 'TLG - TLG',
        passengertype: 'UMUM',
        waNumber: '089089786787',
        price: 40000,
        ticketType: 'NORMAL',
        ticketCode: '5o2N46.3'
      }
    ])

    const getDataDetail = async () => {
      store.dispatch('showLoading')
      const { result, error, message, count } = await apiUseCase.get(`${endpointRit}/${ritId.value}/Tickets${returnUrlPrams(filters.value)}`)
      console.log('res', result)
      totalTrayekRecords.value = count
      dataSource.value = result.map((data: any) => {
        const bookingStatusTemp = {
          class: '',
          label: '-'
        }
        if (data.BookingDraft) {
          if (data.BookingDraft.BookingStatusName.toLowerCase() === 'success') {
            bookingStatusTemp.class = 'text-green'
          } else if (data.BookingDraft.BookingStatusName.toLowerCase() === 'cancel') {
            bookingStatusTemp.class = 'text-red'
          } else if (data.BookingDraft.BookingStatusName.toLowerCase() === 'open') {
            bookingStatusTemp.class = 'text-orange'
          } else if (data.BookingDraft.BookingStatusName.toLowerCase() === 'checkin') {
            bookingStatusTemp.class = 'text-primary'
          } else {
            bookingStatusTemp.class = ''
          }
          bookingStatusTemp.label = data.BookingDraft.BookingStatusName
        }
        return {
          ...data,
          rit: `${data.OriginTerminalCode} - ${data.DestinationTerminalCode}`,
          bookingStatus: bookingStatusTemp
        }
      })
      store.dispatch('hideLoading')
    }

    const closeDialog = () => {
      emit('hideDialog')
    }

    const refreshPage = () => {
      console.log('reload')
    }

    const onSearch = () => {
      console.log('search', inputSearch.value)
      filters.value.search = inputSearch.value
      getDataDetail()
    }

    const onCopy = (val: any) => {
      navigator.clipboard.writeText(val)
      $toast.add({
        severity: 'success',
        detail: 'Kode berhasil disalin',
        group: 'bc',
        closable: false,
        life: 3000
      })
    }

    const onChangePage = (evt: any) => {
      console.log('evt', evt)
      filters.value.pageNumber = evt.page + 1
      getDataDetail()
    }

    const onClickFilterOptions = (evt: any) => {
      console.log('filter')
      refOverlayAction.value.toggle(evt)
    }

    const applyFilter = () => {
      console.log('filter', selectedFilter.value)
      filters.value.custom = []
      filters.value.pageNumber = 1
      if (selectedFilter.value.length > 0) {
        selectedFilter.value.forEach((el: any) => {
          filters.value.custom.push(['originTerminalCodes', '=', el.OriginTerminalCode])
          filters.value.custom.push(['destinationTerminalCodes', '=', el.DestinationTerminalCode])
        })
      }
      getDataDetail()
    }

    const resetFilterOptions = () => {
      selectedFilter.value = []
      filters.value.custom = []
      filters.value.pageNumber = 1
      getDataDetail()
    }

    onMounted(() => {
      console.log('show', showModal)
      getDataDetail()
    })

    return {
      closeDialog,
      showModal,
      inputSearch,
      onSearch,
      icon,
      placeholder,
      moment,
      date,
      refreshPage,
      dummyData,
      onCopy,
      currencyNumberFormat,
      filters,
      totalTrayekRecords,
      onChangePage,
      onClickFilterOptions,
      refOverlayAction,
      filterListDummy,
      selectedFilter,
      dataSource,
      applyFilter,
      resetFilterOptions
    }
  }
}
